const PubSub = window.PubSub;
const $ = window.jQuery;

class Accordion {
    constructor() {
        this.prevWidth = null;

        PubSub.subscribe('ready', this.init.bind(this));
        PubSub.subscribe('draw', this.draw.bind(this));
    }
    init() {
        var self = this;

        this.accordion = $('.component.accordion').each(function (index, elem) {
            var accordion = $(elem);
            var panes = accordion.find('.pane').each(function (index, elem) {
                var pane = $(elem);
                var header = pane.find('.header');
                var content = pane.find('.content');

                if (index === 0) {
                    pane.addClass('opened').removeClass('closed');
                    content.show();
                }

                header.on('click', function (event) {
                    if (pane.hasClass('opened')) {
                        pane.addClass('closing');
                        pane.removeClass('opened');
                        content.slideUp(() => {
                            pane.removeClass('closing');
                            pane.addClass('closed');
                        });
                    } else {
                        pane.addClass('opening');
                        pane.removeClass('closed');
                        content.slideDown(() => {
                            pane.removeClass('opening');
                            pane.addClass('opened');
                        });
                    }
                });
            });
        });
    }
    draw(topic, args) {
        if (args && args.width) {
            this.prevWidth = args.width;
        }
    }
}

export default new Accordion();
